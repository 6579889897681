.map-wrapper {
    position: relative;
    margin-bottom: calculateRem(45px);

    .map {
        height: 400px;

        .infowindow {
            @include font-family(body);
            font-size: calculateRem(13px);

            strong {
                @include font-family(body);
                font-weight: 600;
                font-size: calculateRem(14px);
            }
        }
    }

    .form-wrapper {
        position: absolute;
        right: calculateRem(10px);
        top: calculateRem(10px);

        form {
            display: flex;
            flex-direction: row;
            align-items: center;

            .input-wrapper {
                background-color: getColor(white);
            }

            input[type='text'] {
                width: 300px;
                padding: 0 calculateRem(10px);
                background-color: getColor(white);
                border-color: transparent;
            }

            button {
                i {
                    margin-right: calculateRem(5px);
                }
            }

            button + button {
                margin-left: calculateRem(10px);
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .form-wrapper {
            position: relative;
            right: auto;
            top: auto;

            form {
                display: block;

                input[type='text'] {
                    width: 100%;
                    margin-bottom: calculateRem(10px);
                    border-color: getColor(gray, border);
                }

                .buttons-wrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    button {
                        width: calc(50% - 5px);
                    }
                }
            }
        }
    }
}
