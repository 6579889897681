//Only on outlet page
#page-content {
    .images-section {
        width: calc(70% - 30px);
        float: left;
        padding-top: calculateRem(30px);
        line-height: 0;

        @include media-breakpoint-up(sm) {
            padding-right: calculateRem(30px);
        }

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
}

.images-text {
    display: flex;
    flex-direction: row;

    .images-wrapper {
        flex: 2;
        padding-right: calculateRem(30px);

        img {
            width: calc(50% - 15px);
            float: left;

            &:not(:last-child) {
                margin-bottom: calculateRem(30px);
            }

            &:nth-child(1),
            &:nth-child(4n) {
                width: 100%;
            }

            &:nth-child(2n) {
                margin-right: calculateRem(30px);

                @include media-breakpoint-down(md) {
                    margin-right: calculateRem(15px);
                }
            }
        }
    }
    .text-wrapper {
        flex: 1;
    }

    @include media-breakpoint-down(md) {
        flex-direction: column;

        .text-wrapper {
            order: 1;
        }

        .images-wrapper {
            order: 2;
            padding-right: calculateRem(20px);

            img {
                width: calc(50% - 20px / 2);

                &:not(:last-child) {
                    margin-bottom: 20px;
                }
                &:nth-child(2n) {
                    margin-right: 20px;
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .images-wrapper {
            padding-right: 0;
        }
    }
}
