div.agegate {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    overflow: auto;
    height: 100vh;
    width: 100%;
    background-color: getColor(white);
    display: none;

    body.gated & {
        display: block;
    }

    .agegate-content {
        padding: 0 0 calculateRem(120px);
        text-align: center;
        margin: 0 auto;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-image: url('../dist/img/bg/agegate.jpg');
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 300px auto;

        .logo {
            width: 150px;
            height: 100%;
            margin-top: calculateRem(15px);
            position: relative;
        }

        .agegate-box {
            color: getColor(gray);
            width: 320px;

            h3 {
                margin-top: calculateRem(60px);
            }

            form {
                .check-age {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 0;
                    position: relative;

                    .field-wrapper {
                        margin: 15px 0;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;

                        label {
                            position: relative;
                            padding-top: calculateRem(15px);

                            &:before {
                                @include font-family(medium);
                                font-weight: normal;
                                content: attr(data-label);
                                color: getColor(gray);
                                font-size: calculateRem(20px);
                                text-align: left;
                                position: absolute;
                                left: 0;
                                top: 0;
                            }
                        }

                        input {
                            width: 80px;
                            padding: calculateRem(5px) 0;
                            margin: 0 calculateRem(5px) 0 0;
                            text-align: left;
                            background-color: getColor(white);
                            color: getColor(red);
                            @include font-family(heading);
                            @include font-size(42px);
                            outline: none;
                            border: none;
                            border-radius: 0;
                            border-bottom: 1px solid getColor(gray, light);

                            &::placeholder {
                                color: lighten(getColor(gray, light), 25%);
                            }

                            &::-webkit-outer-spin-button,
                            &::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }
                        }
                    }
                }
                .submit-agegate {
                    width: 100%;
                    margin-top: calculateRem(10px);
                }
            }

            .agegate-or {
                margin: calculateRem(15px) 0;
                display: inline-block;

                &:before,
                &:after {
                    content: '\2014';
                    color: getColor(gray, light);
                    margin: 0 calculateRem(5px);
                }
            }

            .spacer {
                margin: calculateRem(15px) 0 0;
                display: inline-block;
            }

            .error {
                @include transition(all);
                @include font-family(medium);
                font-size: calculateRem(18px);
                opacity: 0;
                color: getColor(red);
                padding: 0;
                display: inline-block;

                &.active {
                    opacity: 1;
                    margin: 0 0 calculateRem(15px) 0;
                }
            }

            .form-check {
                @include font-family(subheading);
                font-size: calculateRem(18px);
            }

            p {
                line-height: calculateRem(21px);
                margin-bottom: 0;
            }

            .btn {
                font-size: calculateRem(20px);
            }
        }
    }

    .noalcohol {
        text-align: center;
        padding: calculateRem(15px) 0;
        font-size: calculateRem(15px);
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}
