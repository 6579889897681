.blockquote {
    padding: calculateRem(45px) 0;

    .text-section + &,
    .text-image + &,
    .blockquote + & {
        padding-top: 0;
    }

    blockquote {
        @include font-family(subheading);
        font-size: calculateRem(24px);
        line-height: calculateRem(30px);
        text-align: center;
        margin: 0;

        &:after {
            content: '\201d';
            display: inline;
        }

        &:before {
            content: '\201c';
            display: inline;
        }
    }

    .author {
        text-align: center;
    }
}
