#page-product {
    .content-wrapper {
        background-image: url('../dist/img/bg/product.jpg');
        background-repeat: no-repeat;
        background-position: 60% 98%;
        background-size: 300px auto;
        padding-bottom: 150px;

        section.text-image {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        .content-wrapper {
            background-position: 270px 98%;
            background-size: 250px auto;
            padding-bottom: 0;

            section.text-image {
                padding-bottom: calculateRem(30px);
            }
        }
    }
}
