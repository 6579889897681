.link-list {
    ul.list-inline {
        @extend .nlst;

        li {
            display: inline-block;
            margin-right: calculateRem(15px);

            &.last-child {
                margin-right: 0;
            }

            a {
                color: getColor(gray);
                text-decoration: underline;
            }
        }
    }
}
