input[type='radio'].form-radio {
    position: absolute;
    left: -9999px;
    display: initial;
    width: auto;

    & + label {
        color: inherit !important;
        cursor: pointer;
        position: relative;
        padding-left: 30px;
        max-width: 100%;
        display: inline-block;
        text-align: left;
        letter-spacing: 0;

        &:before {
            content: '';
            display: block;
            text-align: center;
            border-radius: 50%;
            color: transparent;
            cursor: pointer;
            position: absolute;
            top: 2px;
            left: 0;
            padding-bottom: 0px;
            height: 22px;
            width: 22px;
            border: 1px solid getColor(gray);
            background-color: getColor(white);
            box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
        }

        &:after {
            @include transition(all);
            content: '';
            opacity: 0;
            height: 14px;
            width: 14px;
            background-color: getColor(gray);
            border-radius: 50%;
            position: absolute;
            top: 6px;
            left: 4px;
        }
    }

    &:checked {
        & + label {
            &:before {
                color: getColor(gray);
            }

            &:after {
                opacity: 1;
            }
        }
    }

    &:disabled {
        & + label {
            color: getColor(gray);

            &:before {
                background: transparent !important;
                border: 1px solid getColor(gray) !important;
                color: getColor(gray) !important;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        & + label {
            &:before {
                top: 0px;
                height: 20px;
                width: 20px;
            }

            &:after {
                height: 12px;
                width: 12px;
                top: 4px;
                left: 4px;
            }
        }
    }
}
