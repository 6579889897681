.search-results-wrapper {
    margin-top: calculateRem(30px);
    margin-bottom: calculateRem(60px);

    .result {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: calculateRem(30px) 0;
        border-bottom: 1px solid getColor(gray, border);

        a {
            display: inline-block;

            img {
                margin-right: calculateRem(15px);
                width: 300px;
                max-width: none;
            }
        }

        h2 a {
            color: getColor(blue);
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        margin: calculateRem(15px) 0 calculateRem(30px);

        .result {
            display: block;
        }
    }
}
