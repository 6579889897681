.form-group {
    margin-bottom: calculateRem(15px);

    .form-field {
        flex: 1;
        position: relative;
        margin-bottom: calculateRem(15px);

        &.f2 {
            flex: 2;
        }
        &.f3 {
            flex: 3;
        }

        &.empty {
            flex: 1;
        }

        &.align-right {
            text-align: right;
        }

        label {
            @include font-family(body);
            font-size: calculateRem(16px);
            line-height: calculateRem(16px);
            margin-bottom: calculateRem(5px);
            display: block;
        }

        div.error {
            @include font-family(body);
            color: getColor(red);
            background-color: getColor(white);
            padding: calculateRem(2px) 0;
            font-size: calculateRem(12px);
            margin: calculateRem(5px) 0 0 0;
            display: inline-block;
        }
    }

    .btn {
        max-height: 48px;
    }

    &.inline {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;

        .form-field {
            margin-right: calculateRem(15px);

            &:last-child {
                margin-right: 0;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &.inline {
            flex-wrap: wrap;

            .form-field {
                min-width: 100%;
                margin-right: 0;
                margin-bottom: calculateRem(15px);
            }
        }
    }
}
