a {
    @include transition(all);
    position: relative;
    text-decoration: none;
    color: getColor(red);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:hover {
        text-decoration: underline;
    }
}
