footer {
    border-top: 1px solid getColor(gray, light);
    max-height: $footer-height + $bar18-height;
    height: $footer-height + $bar18-height;
    padding-bottom: $bar18-height;

    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: calculateRem(60px) calculateRem(30px);

        .logo {
            width: 120px;
            display: inline-block;

            img {
                height: 100%;
            }
        }

        .footer-content-box {
            width: 33%;
            max-width: 330px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            .link-list {
                li {
                    margin: 0 calculateRem(5px);
                }
            }

            &:last-child {
                text-align: right;
                align-items: flex-end;
                flex-direction: column;
            }
        }
    }

    @include media-breakpoint-portrait(tablet) {
        @include transition(all);
        max-height: none;
        height: auto;
        border-color: getColor(gray, border);

        .nav-expanded & {
            transform: translateX(-85%);
        }

        .container {
            flex-direction: column;
            padding: calculateRem(30px) 20px calculateRem(45px);
            text-align: center;

            .logo {
                background-color: getColor(white);
                margin: 0 auto;
                padding: 0 calculateRem(15px);
                order: 1;
                transform: translateY(-100%);

                img {
                    height: auto;
                }
            }

            .footer-content-box {
                width: 100%;
                max-width: none;
                order: 2;

                &:last-child {
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: calculateRem(30px);

                    .link-list {
                        li {
                            display: inline-block;
                            margin: 0 calculateRem(2px);

                            &:last-child {
                                display: block;
                            }
                        }
                    }

                    .social {
                        display: block;
                        margin-bottom: 0;

                        li {
                            display: inline-block;
                            margin-right: calculateRem(10px);

                            &:first-child {
                                display: block;
                                padding-bottom: calculateRem(10px);
                                text-align: center;
                            }

                            a {
                                i {
                                    font-size: calculateRem(30px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        @include transition(all);
        max-height: none;
        height: auto;
        border-color: getColor(gray, border);

        .nav-expanded & {
            transform: translateX(-85%);
        }

        .container {
            flex-direction: column;
            padding: calculateRem(30px) 20px calculateRem(45px);
            text-align: center;

            .logo {
                background-color: getColor(white);
                margin: 0 auto;
                padding: 0 calculateRem(15px);
                order: 1;
                transform: translateY(-100%);

                img {
                    height: auto;
                }
            }

            .footer-content-box {
                width: 100%;
                max-width: none;
                order: 2;

                &:last-child {
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: calculateRem(30px);

                    .link-list {
                        li {
                            display: inline-block;
                            margin: 0 calculateRem(2px);

                            &:last-child {
                                display: block;
                            }
                        }
                    }

                    .social {
                        display: block;
                        margin-bottom: 0;

                        li {
                            display: inline-block;
                            margin-right: calculateRem(10px);

                            &:first-child {
                                display: block;
                                padding-bottom: calculateRem(10px);
                                text-align: center;
                            }

                            a {
                                i {
                                    font-size: calculateRem(30px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
