//Only on styleguide page
#page-styleguide {
    .triptych {
        position: relative;
        margin: calculateRem(30px) 0;

        .triptych-title {
            @include font-family(subheading);
            position: absolute;
            font-size: calculateRem(280px);
            line-height: calculateRem(280px);
            z-index: -1;
            left: 26%;
            top: -32px;
        }

        .images-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;
            z-index: 1;

            .triptych-component {
                width: calc(33.333% - 20px);

                &.triptych-1 {
                    margin-top: calculateRem(180px);
                }
                &.triptych-2 {
                    margin-top: calculateRem(240px);
                }
                &.triptych-3 {
                    margin-top: calculateRem(110px);
                }

                .triptych-image {
                    .image-hover {
                        width: 100%;
                    }
                }

                .text {
                    margin-top: calculateRem(5px);
                }
            }
        }

        &.even {
            .triptych-title {
                right: 26%;
                left: auto;
            }
            .images-wrapper {
                .triptych-component {
                    &.triptych-1 {
                        margin-top: calculateRem(110px);
                    }
                    &.triptych-3 {
                        margin-top: calculateRem(180px);
                    }
                }
            }
        }

        @include media-breakpoint-portrait(tablet) {
            .triptych-title {
                position: relative;
                right: auto;
                left: auto;
                font-size: calculateRem(240px);
                line-height: calculateRem(240px);
            }

            .images-wrapper {
                flex-wrap: wrap;

                .triptych-component {
                    width: calc(50% - 20px);

                    &.triptych-1,
                    &.triptych-2 {
                        margin-top: 0 !important;
                    }

                    &.triptych-2 {
                        transform: translateY(-200px);
                    }

                    &.triptych-3 {
                        margin-top: 30px !important;
                    }
                }
            }

            &.even {
                .triptych-title {
                    position: relative;
                    right: auto;
                    left: auto;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .triptych-title {
                position: relative;
                font-size: calculateRem(200px);
                line-height: calculateRem(200px);
            }

            .images-wrapper {
                display: block;

                .triptych-component {
                    width: 100%;

                    &.triptych-1 {
                        transform: none !important;
                        margin-top: -30px !important;
                    }

                    &.triptych-2,
                    &.triptych-3 {
                        transform: none !important;
                        margin: calculateRem(30px) 0 0 !important;
                    }
                }
            }
        }
    }
}
