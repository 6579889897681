nav {
    .nav-wrapper {
        ul {
            &.navigation {
                @extend .nlst;
                display: flex;
                flex-direction: row;
                align-items: center;

                li {
                    display: inline-block;
                    margin: 0 calculateRem(15px);

                    &.custombuttoncss a {
                        background-color: #c8123c;
                        color: white !important;
                        padding: 7px !important;
                    }

                    &.active {
                        a:not(.search) {
                            &:before {
                                width: 100%;
                            }
                        }
                    }

                    &:hover {
                        a:not(.search) {
                            &:before {
                                width: 100%;
                            }
                        }

                        a {
                            text-decoration: none;
                        }
                    }

                    &:last-child {
                        margin: 0;
                    }

                    a {
                        @include font-family(heading);
                        text-transform: uppercase;
                        text-decoration: none;
                        color: getColor(blue);
                        padding: 0;
                        position: relative;

                        &:before {
                            @include transition(all);
                            content: '';
                            position: absolute;
                            bottom: -2px;
                            left: 0;
                            width: 0%;
                            border-bottom: 2px solid getColor(red);
                        }
                    }
                }
            }
            &.social {
                display: none;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .nav-wrapper {
            @include transition(all);
            display: block;
            opacity: 1;
            background: getColor(white);
            transform: translateX(100%);
            position: fixed;
            top: 0;
            right: 0;
            width: 85%;
            height: 100vh;
            overflow: auto;
            padding-bottom: calculateRem(30px);

            ul.navigation {
                margin-top: $nav-height;
                display: block;

                li {
                    display: block;
                    background-color: getColor(white);
                    margin: 0;
                    text-align: center;

                    a {
                        padding: calculateRem(15px) 0;
                        display: inline-block;
                        font-size: calculateRem(24px);

                        &:before {
                            bottom: calculateRem(10px);
                        }
                    }

                    &.active {
                        a {
                            &:before {
                                bottom: calculateRem(8px);
                            }
                        }
                    }
                }
            }

            .nav-expanded & {
                transform: translateX(0%);
                opacity: 1;

                &:before {
                    opacity: 1;
                }

                .search-bar {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    width: 80%;
                    margin: calculateRem(15px) auto 0;
                    position: relative;

                    form {
                        opacity: 1;
                        z-index: 1;
                        position: relative;
                        right: auto;
                        top: auto;
                        width: 100%;

                        input {
                            border: 0;
                            border-bottom: 1px solid getColor(gray, border);
                        }
                    }

                    button {
                        background: none;
                        position: absolute;
                        right: 0;
                        z-index: 2;
                    }
                }
            }
        }
    }
}
