$colorPalette: (
    red: (
        base: #c8073c,
    ),
    blue: (
        base: #023e84,
    ),
    white: (
        base: #ffffff,
    ),
    black: (
        base: #000000,
    ),
    gray: (
        base: #808080,
        light: #a7a9ae,
        border: #dcdddf,
    ),
    green: (
        base: #007d04,
    ),
);
@each $label, $tones in $colorPalette {
    @each $key, $value in $tones {
        @if $key == 'base' {
            .c-#{$label} {
                color: $value;
            }
            .bg-#{$label} {
                background-color: $value;
            }
        } @else {
            .c-#{$label}-#{$key} {
                color: $value;
            }
            .bg-#{$label}-#{$key} {
                background-color: $value;
            }
        }
    }
}

.text-color-red {
    color: getColor(red);
}
