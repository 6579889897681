.text-image {
    display: -ms-grid;
    display: grid;
    grid-column-gap: 30px;

    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;

    -ms-grid-columns: 1fr 30px 1fr;
    grid-template-columns: 1fr 1fr;

    grid-template-areas: 'titles image' 'content image';

    .text-image__title {
        -ms-grid-row: 1;
        -ms-grid-column: 1;

        grid-area: titles;
        align-self: end;
        -ms-grid-row-align: end;
    }

    .text-image__content {
        position: relative;
        -ms-grid-row: 2;
        -ms-grid-column: 1;

        grid-area: content;

        &.signature {
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size: 250px auto;
        }
    }

    .text-image__image {
        -ms-grid-row-span: 2;
        -ms-grid-row: 1;
        -ms-grid-column: 3;
        grid-area: image;
        height: 100%;

        .image-hover {
            width: 100%;
            align-items: center;
        }
    }

    &.has-scroll-animation {
        .text-image__title {
            padding-top: calculateRem(60px);
        }
        .text-image__content {
            padding-bottom: calculateRem(120px);
        }
    }

    &.added-padding {
        .text-image__title,
        .text-image__content {
            padding-left: calculateRem(45px);
            padding-right: calculateRem(45px);
        }
    }

    &.product {
        background-image: url('../dist/img/bg/home-product.jpg');
        background-repeat: no-repeat;
        background-position: right center;
        background-size: calc(50% - 90px) auto;

        .text-image__title,
        .text-image__content {
            padding: 0 calculateRem(45px);
        }

        .text-image__image {
            text-align: left;
            min-height: 450px;

            img {
                max-height: 450px;
            }
        }

        &.left {
            background-position: left center;

            .text-image__image {
                text-align: right;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        &.has-scroll-animation {
            .text-image__title {
                padding-top: 0;
            }
            .text-image__content {
                padding-bottom: 0;
            }
        }

        &.product,
        &.added-padding {
            .text-image__title,
            .text-image__content {
                padding: 0;
            }
        }
    }

    @include media-breakpoint-portrait(tablet) {
        &.has-scroll-animation {
            .text-image__title {
                padding-top: 0;
            }
            .text-image__content {
                padding-bottom: 0;
            }
        }

        &.product,
        &.added-padding {
            .text-image__title,
            .text-image__content {
                padding: 0;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        grid-row-gap: calculateRem(15px);

        -ms-grid-rows: auto auto auto;
        grid-template-rows: auto auto auto;

        -ms-grid-columns: auto;
        grid-template-columns: auto;

        grid-template-areas: 'image' 'titles' 'content';

        .text-image__title {
            h1 {
                margin-bottom: 0;
            }
        }

        &.has-scroll-animation {
            .text-image__title {
                padding-top: 0;
            }
            .text-image__content {
                padding-bottom: 0;
            }
        }

        .text-image__content {
            .btn {
                margin: 0 auto;
            }

            &.signature {
                background-image: none;
            }
        }

        .text-image__image {
            .image-hover {
                margin: 0;
            }
        }

        &.product {
            background-image: none;

            .text-image__title,
            .text-image__content {
                padding: 0;
            }

            .text-image__image {
                text-align: center;
                background-repeat: no-repeat;
                background-position: top center;
                background-size: 100% auto;
                min-height: 0;

                img {
                    max-height: 400px;
                }
            }

            &.left {
                .text-image__image {
                    text-align: center;
                }
            }
        }

        p {
            line-height: 20px;
        }
    }

    &.left {
        grid-template-areas: 'image titles' 'image content';

        .text-image__title {
            -ms-grid-row: 1;
            -ms-grid-column: 3;
            -ms-grid-row-align: end;

            grid-area: titles;
            align-self: end;
        }

        .text-image__content {
            -ms-grid-row: 2;
            -ms-grid-column: 3;

            grid-area: content;
        }

        .text-image__image {
            -ms-grid-row-span: 2;
            -ms-grid-row: 1;
            -ms-grid-column: 1;

            grid-area: image;
        }

        @include media-breakpoint-down(sm) {
            grid-template-areas: 'image' 'titles' 'content';
        }
    }
}
