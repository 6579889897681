.campaign-component,
.tailor-made-campaign-component {
    img.mobile-image {
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    .form-group {
        display: flex;
        justify-content: space-between;

        label {
            font-family: 'DINCond-Medium';
            font-weight: lighter;
            font-size: 14px;
            margin-bottom: 10px;
            display: block;
        }
    }

    .form-filled {
    }

    label.select select {
        width: 100%;
        height: 25px;
        display: block;
        border: 1px solid #dcdddf;
        color: #2a2a2a;
    }

    .form-filled {
        width: 49%;

        .below {
            width: 100%;
        }

        textarea {
            display: block;
            padding: 5px;
        }

        input {
            min-height: 30px;
        }
    }

    textarea#slogan {
        font-family: TradeGothicLT-Light;
        border: 0;
        border-bottom: 1px solid #dcdddf;
        line-height: 100%;
        min-height: 45px;
        font-size: 1rem;
        position: relative;
        width: 100%;
        background-color: transparent;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        height: 99px;
    }

    .container {
        display: flex;
    }

    .image-banner {
        flex: 1;
        margin-right: 30px;

        img.side-image {
            width: 100%;
            background-size: cover;
            object-fit: cover;
            height: 100%;
        }
    }

    .row {
        flex: 2;
    }

    .form-check label {
        font-family: TradeGothicLT-Light;
        font-size: 13px;
        line-height: 19px !important;
        margin-bottom: 0px;
    }

    .form-wrapper .form-group:last-child {
        margin-bottom: 0px;
    }

    .kassabon {
        margin-bottom: 20px;

        label {
            font-family: 'DINCond-Medium';
            font-size: 13px;
        }
    }

    .campaign-title {
        text-align: left;
    }

    .campaign-subtitle {
        text-align: left;
        font-size: 18px;
        color: #c8073c;
    }

    .drop-region {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .send-div {
        display: flex;
        align-self: flex-end;
    }

    @media (max-width: 991.98px) {
        .image-banner {
            display: none;
        }
        .form-filled {
            width: 100%;
        }
        .form-group {
            flex-direction: column;
        }
        .form-group label {
            margin-top: 10px;
        }
        .kassabon {
            margin-bottom: 10px;
        }
        .send-div {
            margin-top: 10px;
        }
    }
}
