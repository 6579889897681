main {
    display: block;
    position: relative;
    padding-bottom: calculateRem(30px);
    @include transition(all);

    .content {
        width: 100%;
        margin: 0 auto;
        display: block;

        @include media-breakpoint-down(sm) {
            width: 100% !important;
            max-width: 100% !important;
        }

        @include media-breakpoint-up(md) {
            max-width: 720px;
        }

        @include media-breakpoint-up(lg) {
            max-width: 960px;
        }

        @include media-breakpoint-up(xl) {
            max-width: 1140px;
        }
    }

    .nav-expanded & {
        transform: translateX(-85%);
    }
}
