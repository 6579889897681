.header-image {
    min-height: 450px;
    background: transparent;

    @include media-breakpoint-down(sm) {
        height: 200px;
        min-height: 0;
        margin: -15px -20px;
    }
}
