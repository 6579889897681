$gutter: $padding * 2;
$heightsm: 270px;
$height: 300px;
$heightlg: 550px;
$heightmd: 400px;

.grid {
    max-width: calc(100% + #{$gutter});

    &:not(.teasers) {
        &:after {
            content: '';
            display: block;
            clear: both;
        }

        @include media-breakpoint-up(md) {
            margin-right: calculateRem(-88px);
        }

        @include media-breakpoint-between(sm, md) {
            margin-right: calculateRem(-65px);
        }

        .grid-item,
        .grid-sizer {
            width: calc(33.333% - #{$gutter});
            opacity: 0;
            transform: scale(0.8);
            transition: all 0.3s;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }

            @include media-breakpoint-between(sm, md) {
                width: calc(50% - #{$gutter});
            }
        }

        .magnolia-editor & {
            .grid-item,
            .grid-sizer {
                opacity: 1;
                transform: scale(1);
            }
        }

        .grid-item {
            padding: 0 0 calculateRem($padding * 4);

            .image-wrapper {
                background-position: center top;
            }

            a {
                color: unset;
            }

            &:hover {
                a {
                    text-decoration: none;
                }
            }

            .img {
                position: relative;
                height: $height;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center top;
                width: 100%;
            }

            &.lg {
                .img {
                    height: $heightlg;
                }
            }

            &.md {
                .img {
                    height: $heightmd;
                }
            }

            &.sm {
                .img {
                    height: $heightsm;
                }
            }

            h3 {
                margin: calculateRem($padding) 0 calculateRem($padding / 2) 0;
                font-size: calculateRem(22px);
                color: getColor(gray);

                & + h2 {
                    margin-top: 0;
                }
            }

            h2 {
                @include font-family(heading);
                @include font-weight(regular);
                font-size: calculateRem(36px);
                text-transform: uppercase;
                margin: calculateRem($padding / 2) 0;
                line-height: 100%;
                color: getColor(blue);
            }

            .content {
                font-size: calculateRem(16px);
                line-height: 150%;
                display: inline-block;
                color: getColor(gray);
            }
        }
    }

    &.teasers {
        $gutter: $padding * 2;
        $height: 500px;
        $heightlg: 600px;
        $heightlgmobile: $heightlg / 2;
        $heightmd: 400px;
        $heightmdmobile: $heightmd / 2;
        $verticalpadding: $padding * 2;

        &:after {
            content: '';
            display: block;
            clear: both;
        }

        .teaser,
        .teaser-sizer {
            transition: all 0.4s;
            width: calc(33.333% - #{$gutter});

            @include media-breakpoint-down(sm) {
                width: 100%;
            }

            @include media-breakpoint-between(sm, md) {
                width: calc(50% - #{$gutter});
            }
        }

        .teaser {
            color: getColor(gray);
            padding: $padding * 2 0;

            @include media-breakpoint-up(sm) {
                &.page-title-text {
                    top: 50px !important;
                }
            }

            .img {
                position: relative;
                height: $height;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center top;
            }

            &.thin {
                .img {
                    margin: 0 $padding * 2;
                }
                h2.vertical {
                    width: $height - $verticalpadding;
                }
            }

            &.lg {
                .img {
                    height: $heightlg;
                }

                h2.vertical {
                    width: $heightlg - $verticalpadding;
                }
            }

            &.md {
                .img {
                    height: $heightmd;
                }

                h2.vertical {
                    width: $heightmd - $verticalpadding;
                }
            }

            h2 {
                margin: 0;
                text-transform: uppercase;
                line-height: 100%;
                transform: translateY(-15px);
                color: getColor(red);

                &.center {
                    text-align: center;
                }

                &.vertical {
                    transform: rotate(-90deg);
                    transform-origin: right;
                    position: absolute;
                    right: $padding * 2;
                    top: $padding * 2;

                    &.left {
                        transform-origin: left;
                        transform: rotate(-90deg) translateX(-100%);
                        left: $padding * 2;
                        right: auto;
                    }
                }
            }

            $directions: (
                l: '-left',
                r: '-right',
                t: '-top',
                x: (
                    '-right',
                    '-left',
                ),
            );

            $spaces: (1, 2, 4);

            @each $d, $a in $directions {
                @each $s in $spaces {
                    @if not is-map($a) {
                        @if $s == 1 {
                            .p#{$d} {
                                @each $aa in $a {
                                    padding#{$aa}: #{$padding} !important;
                                }
                            }
                        } @else {
                            .p#{$d}#{$s} {
                                @each $aa in $a {
                                    padding#{$aa}: #{$s * $padding} !important;
                                }
                            }
                        }
                    } @else {
                        @if $s == 1 {
                            .p#{$d} {
                                padding#{$a}: #{$padding} !important;
                            }
                        } @else {
                            .p#{$d}#{$s} {
                                padding#{$a}: #{$s * $padding} !important;
                            }
                        }
                    }
                }
            }

            .center {
                text-align: center;
            }

            .content,
            span {
                @include font-family(subheading);
                font-size: calculateRem(13px);
                line-height: 150%;
                display: inline-block;
            }
        }
    }
}

.teaser-section {
    @include media-breakpoint-up(sm) {
        margin-right: calculateRem(-32px);
    }
}
