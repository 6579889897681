ul.social {
    @extend .nlst;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: calculateRem(30px);

    li {
        margin-right: calculateRem(15px);

        &:last-child {
            margin-right: 0;
        }

        a {
            i {
                @include transition(color);
                font-size: calculateRem(40px);
                display: inline-block;
                width: calculateRem(40px);
                text-align: center;
                text-decoration: none;
            }
        }

        &:hover {
            a i {
                text-decoration: none;
                color: getColor(blue);
            }
        }
    }
}
