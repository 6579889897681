.text-video-component {
    display: flex;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    .video-area {
        display: flex;
        align-items: center;

        flex: 0 0 60%;

        .video-hd-ratio {
            position: relative;

            width: 100%;
            padding-top: 56.25%;

            .video-cover-image {
                position: absolute;
                top: 0;
                left: 0;
                background-position: center;
                background-size: cover;
                height: 100%;
                width: 100%;
                z-index: 2000;

                .video-play-button {
                    position: absolute;

                    top: 50%;
                    left: 50%;

                    transform: translate(-50%, -50%);

                    color: getColor(white);

                    margin: 0;

                    &:hover,
                    &:focus,
                    &:active {
                        color: getColor(white);

                        svg {
                            path {
                                fill: getColor(white);
                            }
                        }
                    }

                    .icon-play {
                        font-size: 2rem;
                    }
                }
            }

            iframe {
                position: absolute;

                top: 0;
                left: 0;

                width: 100%;
            }
        }
    }

    .content-area {
        flex: 0 1 auto;
        padding: calculateRem(30px);

        @include media-breakpoint-down(md) {
            padding: calculateRem(30px) 0;
        }

        // Center content vertical
        display: flex;
        flex-direction: column;
        justify-content: center;

        .peroni-pasta-night-buttons {
            margin-top: calculateRem(30px);
        }
    }

    .hidden {
        display: none !important;
    }
}
