input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input[type='text'],
input[type='email'],
input[type='file'],
input[type='tel'] {
    @include font-family(body);
    border: 0;
    border-bottom: 1px solid getColor(gray, border);
    line-height: 100%;
    min-height: $input-height;
    font-size: calculateRem(16px);
    position: relative;
    width: 100%;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-border-radius: 0;

    &:focus {
        outline: none;
    }

    .form-field & {
        z-index: 1;
    }

    .form-field.has-icon & {
        width: 100%;
        padding-left: calculateRem(60px);
    }

    &::placeholder {
        color: getColor(gray);
    }

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px white inset;
    }
}

.input-wrapper {
    position: relative;

    &:before {
        @include transition(transform);
        @include font-family(medium);
        font-weight: normal;
        content: attr(data-placeholder);
        color: getColor(gray);
        font-size: calculateRem(16px);
        text-align: left;
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(50%);
        z-index: -1;
    }

    &.focus {
        &:before {
            transform: translateY(-5px);
        }
    }

    input {
        line-height: 100%;
        min-height: 30px;
        margin-top: 15px;
    }
}
