.contact-form-component {
    padding-top: 3.75rem;

    img.mobile-image {
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    .form-group {
        display: flex;
        justify-content: space-between;

        label {
            font-family: 'DINCond-Medium';
            font-weight: lighter;
            font-size: 14px;
            margin-bottom: 10px;
            display: block;
        }
    }

    label.select select {
        width: 100%;
        height: 25px;
        display: block;
        border: 1px solid #dcdddf;
        color: #2a2a2a;
    }

    .form-filled {
        &.with-side-image {
            &.x-small-size {
                width: 10%;
            }

            &.small-size {
                width: 32%;
            }

            &.medium-size {
                width: 36%;
            }

            &.big-size {
                width: 49%;
            }
        }

        &.without-side-image {
            &.x-small-size {
                width: 6.5%;
            }

            &.small-size {
                width: 32.5%;
            }

            &.medium-size {
                width: 41%;
            }

            &.big-size {
                width: 49%;
            }
        }

        &.full-size {
            width: 100%;
        }

        .below {
            width: 100%;
        }

        textarea {
            display: block;
            padding: 5px;
            font-family: TradeGothicLT-Light;
            border: 0;
            border-bottom: 1px solid #dcdddf;
            line-height: 100%;
            min-height: 45px;
            font-size: 1rem;
            position: relative;
            width: 100%;
            background-color: transparent;
            -webkit-appearance: none;
            -webkit-border-radius: 0;
        }

        input {
            min-height: 30px;
        }
    }

    .container {
        display: flex;
    }

    .image-banner {
        flex: 1;
        margin-right: 30px;

        img.side-image {
            width: 100%;
            background-size: cover;
            object-fit: cover;
            height: 100%;
        }
    }

    .row {
        flex: 2;
    }

    .form-check label {
        font-family: TradeGothicLT-Light;
        font-size: 13px;
        line-height: 19px !important;
        margin-bottom: 0px;
    }

    .form-wrapper .form-group:last-child {
        margin-bottom: 0px;
    }

    .title {
        text-align: left;
    }

    .sub-title {
        text-align: left;
        font-size: 18px;
        color: #c8073c;
    }

    .intro-text {
        margin-bottom: 10px;
    }

    @media (max-width: 991.98px) {
        .image-banner {
            display: none;
        }

        .form-filled,
        .form-filled.without-side-image.x-small-size,
        .form-filled.without-side-image.small-size,
        .form-filled.without-side-image.medium-size,
        .form-filled.without-side-image.big-size,
        .form-filled.with-side-image.x-small-size,
        .form-filled.with-side-image.small-size,
        .form-filled.with-side-image.medium-size,
        .form-filled.with-side-image.big-size {
            width: 100%;
        }

        .form-group {
            flex-direction: column;
        }

        .form-group label {
            margin-top: 10px;
        }

        input#submit-form {
            margin-top: 1em;
        }
    }
}
