$width: 2px;
$height: 50px;

.scroll-animation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: 0px;
    position: absolute;
    cursor: s-resize;
    left: -10px;

    @include media-breakpoint-down(lg) {
        display: none;
    }

    .text {
        @include font-family(heading);
        transform: rotate(270deg);
        color: getColor(gray, light);
        margin-bottom: calculateRem(15px);
        font-size: calculateRem(10px);
    }

    .line,
    .animated-line {
        width: $width;
        height: $height;
    }

    .line {
        background: getColor(gray, border);

        .animated-line {
            background: getColor(red);
            animation: animateHeight 2s infinite;
        }
    }

    @keyframes animateHeight {
        from {
            height: 0;
        }
        to {
            height: 50px;
        }
    }
}
