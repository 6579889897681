#page-home {
    .content-wrapper {
        background-image: url('../dist/img/bg/home.jpg');
        background-repeat: no-repeat;
        background-position: right 82%;
        background-size: 300px auto;
    }
    .main.container {
        h3 {
            color: getColor(red);
        }
    }

    @include media-breakpoint-down(sm) {
        .content-wrapper {
            background-position: 270px 82%;
            background-size: 250px auto;
        }
    }
}
