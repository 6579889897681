table {
    &.table-responsive {
        thead th,
        tbody td {
            padding: calculateRem(6px) calculateRem(10px);
            vertical-align: top;
            line-height: calculateRem(20px);
            text-align: left;
            letter-spacing: 0;
        }

        thead {
            th {
                border: 1px solid getColor(gray);
                border-bottom: 3px solid getColor(gray);
                background-color: getColor(gray);
                font-weight: 800;
            }
        }
        tbody {
            tr {
                &:nth-child(even) {
                    background-color: lighten(getColor(gray), 4%);
                }
                td {
                    border: 1px solid getColor(gray);
                }
            }
        }
    }
}

.table-responsive {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $next: breakpoint-next($breakpoint, $grid-breakpoints);
        $infix: breakpoint-infix($next, $grid-breakpoints);

        &#{$infix} {
            @include media-breakpoint-down($breakpoint) {
                display: block;
                width: 100%;
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;
                -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057

                // Prevent double border on horizontal scroll due to use of `display: block;`
                > .table-bordered {
                    border: 0;
                }
            }
        }
    }
}
