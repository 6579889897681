.text-image-blue-variation {
    display: flex;

    margin-bottom: calculateRem(30px);

    padding: 0;

    background: getColor(blue);

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    .image-area {
        flex: 0 0 60%;
        margin: calculateRem(15px);

        @include media-breakpoint-down(md) {
            height: 300px;
        }

        img {
            object-fit: cover;

            height: 100%;
            width: 100%;
        }
    }

    .content-area {
        flex: 0 1 auto;
        padding: calculateRem(30px) calculateRem(30px) calculateRem(30px) calculateRem(15px);

        // Center content vertical
        display: flex;
        flex-direction: column;
        justify-content: center;

        .peroni-pasta-night-buttons {
            margin-top: calculateRem(30px);
        }
    }

    &.image-content-reverted {
        .image-area {
            order: 1;
            flex: 0 1 auto;

            @include media-breakpoint-down(md) {
                order: 0;
            }
        }

        .content-area {
            padding: calculateRem(30px) calculateRem(45px) calculateRem(30px) calculateRem(30px);
            order: 0;
            flex: 0 0 60%;

            @include media-breakpoint-down(md) {
                padding: calculateRem(30px) calculateRem(15px) calculateRem(30px) calculateRem(30px);
                order: 1;
            }
        }
    }

    &.original-image-size {
        .image-area {
            display: flex;
            justify-content: center;
            align-items: center;

            @include media-breakpoint-down(md) {
                height: initial;
            }

            img {
                object-fit: cover;

                height: auto;
            }
        }
    }

    // Set all text to color white
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    strong,
    span {
        color: getColor(white);
    }
}
