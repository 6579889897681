section.image {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;

    .image-wrapper {
        flex: 1;
        margin-right: calculateRem(30px);

        &:last-of-type {
            margin-right: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        display: block;

        .image-wrapper {
            margin-right: 0;
            margin-bottom: calculateRem(15px);
        }
    }
}
