@import 'variables';

@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?z50grx');
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?z50grx#iefix') format('embedded-opentype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?z50grx') format('truetype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?z50grx') format('woff'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?z50grx##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-play {
    &:before {
        content: $icon-play;
    }
}
.icon-whatsapp {
    &:before {
        content: $icon-whatsapp;
    }
}
.icon-spotify {
    &:before {
        content: $icon-spotify;
    }
}
.icon-albert-heijn {
    &:before {
        content: $icon-albert-heijn;
    }
}
.icon-map-marker {
    &:before {
        content: $icon-map-marker;
    }
}
.icon-search {
    &:before {
        content: $icon-search;
    }
}
.icon-arrow {
    &:before {
        content: $icon-arrow;
    }
}
.icon-facebook-square {
    &:before {
        content: $icon-facebook-square;
    }
}
.icon-check {
    &:before {
        content: $icon-check;
    }
}
.icon-button {
    &:before {
        content: $icon-button;
    }
}
.icon-facebook {
    &:before {
        content: $icon-facebook;
    }
}
.icon-instagram {
    &:before {
        content: $icon-instagram;
    }
}
