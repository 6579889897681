//Only on outlet page
#page-content {
    .text-section {
        width: 30%;
        float: left;
        padding-top: calculateRem(60px);

        @include media-breakpoint-down(md) {
            width: 100%;
            padding-top: calculateRem(30px);
            padding-bottom: 0;
            margin-bottom: 0;

            p:last-child {
                margin-bottom: 0;
            }
        }

        .col {
            h1,
            h2,
            h3 {
                padding-right: 0;
            }
        }
    }
}

.text-section {
    @include media-breakpoint-up(lg) {
        &.centered {
            .text {
                width: 60%;
                max-width: 600px;
                margin: 0 auto;
            }
        }
    }

    .text {
        .btn.bordered {
            margin: 0 auto;
        }

        .column1 {
            columns: 1 !important;
            column-gap: 0;
        }

        &.column2 {
            columns: 2;
            column-gap: calculateRem(45px);
        }

        @include media-breakpoint-down(md) {
            columns: 1 !important;
        }
    }

    &.centered + section.image {
        width: 70%;
        max-width: 700px;
        margin: 0 auto;
    }

    @include media-breakpoint-down(md) {
        &.centered + section.image {
            width: 100%;
            max-width: 700px;
            margin: 0 auto;
            padding: 0;

            .image-wrapper {
                margin-bottom: 0;
            }
        }
    }
}
