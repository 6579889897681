.newsletter-form {
    width: 100%;

    .form-group {
        margin-bottom: 0;

        .form-field {
            width: 200px;
        }
    }

    form {
        position: relative;

        .message {
            display: none;
        }

        &.processing {
            &:before {
                content: '';
                height: 100%;
                width: 100%;
                position: absolute;
                background-color: rgba(getColor(white), 0.8);
                top: 0;
                left: 0;
                z-index: 2;
            }

            &:after {
                content: '';
                height: 30px;
                width: 30px;
                border: 4px solid rgba(getColor(gray), 0.4);
                border-top: 4px solid getColor(blue);
                border-radius: 30px;
                animation: spin 0.5s infinite cubic-bezier(0.42, 0, 1, 1);
                position: absolute;
                left: calc(50% - 15px);
                top: calc(50% - 15px);
                z-index: 3;
            }
        }

        &.done {
            .form-group {
                display: none;
            }
            .message {
                display: block;

                &.error {
                    color: getColor(red);
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        h3 {
            font-size: calculateRem(26px);
        }

        .form-group.inline {
            margin-bottom: calculateRem(15px);

            .form-field,
            .form-button,
            .form-button .btn {
                width: 100%;
            }

            input {
                font-size: 16px;
            }
        }
    }
}
