header {
    padding: calculateRem(30px) 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 11;

    > .logo-wrapper {
        width: 140px;

        .logo {
            width: 140px;
            height: 100%;
            display: inline-block;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    @include media-breakpoint-down(lg) {
    }

    @include media-breakpoint-down(md) {
        padding: calculateRem(8px) calculateRem(15px);
        border-bottom: 1px solid getColor(gray, border);
        background: getColor(white);
        height: $nav-height;
        position: fixed !important;
        top: 0;
        left: 0;
        @include transition(all);

        &.container {
            max-width: none;
        }

        > .logo-wrapper {
            width: 100px;
            height: 45px;
            @include transition(all);
            margin: 0 auto;

            .logo {
                width: 100px;
                height: 45px;
            }

            .nav-expanded & {
                width: 100%;
                transform: translateX(-35%);

                img {
                    height: 45px;
                }
            }
        }
    }
}
