html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    min-height: 100%;
    font-size: 16px;

    @include media-breakpoint-down(lg) {
        font-size: 18px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 14px;
    }
}

body {
    background-color: getColor(white);
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: auto;
    color: getColor(gray);

    &.gated {
        overflow: hidden;
    }

    .content-wrapper {
        background-color: getColor(white);
        width: 100%;
        max-width: 1400px;
        min-height: calc(100vh - 275px - #{$bar18-height});
        margin: 0 auto;
        transition: all 0.2s ease;
        position: relative;
    }

    &:before {
        transition: transform 0.2s ease, right 0.2s ease;
        transform: translateX(15vw);
        content: '';
        position: fixed;
        top: 0;
        right: 0vw;
        height: 100vh;
        width: 15vw;
        background: linear-gradient(to right, rgba(getColor(gray), 0.2) 0%, rgba(getColor(gray), 0.4) 100%);
        z-index: 12;
        opacity: 0;
        visibility: hidden;
    }

    &.nav-expanded {
        overflow: hidden;

        &:before {
            opacity: 1;
            transform: translateX(0);
            right: 85vw;
            width: 15vw;
            visibility: visible;
        }
    }

    &.loading {
        overflow: hidden;
    }

    .content-wrapper {
        @include media-breakpoint-down(md) {
            padding-top: $nav-height + 15px;
        }
    }

    &.cookie-bar-showing {
        overflow: hidden;
    }
}
