.carousel {
    display: -ms-grid;
    display: grid;
    grid-column-gap: 30px;
    position: relative;
    z-index: 1;

    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;

    -ms-grid-columns: 1fr 30px 1fr;
    grid-template-columns: 1fr 1fr;

    grid-template-areas: 'titles swiper' 'content swiper';

    &.has-cta {
        padding-bottom: calculateRem(110px);
    }

    .carousel__title {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-row-align: end;

        grid-area: titles;
        align-self: end;
        padding: 0 calculateRem(45px);
    }

    .carousel__content {
        position: relative;
        -ms-grid-row: 2;
        -ms-grid-column: 1;

        grid-area: content;
        padding: 0 calculateRem(45px);

        .btn {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 2;
            transform: translate(50%, 50%) translate(20px, 4px);
        }
    }

    .carousel__swiper {
        -ms-grid-row-span: 2;
        -ms-grid-row: 1;
        -ms-grid-column: 3;

        grid-area: swiper;
        position: relative;
        overflow: hidden;

        .swiper-container {
            padding: 0 0 calculateRem(20px) 0;
            position: relative;

            .swiper-next {
                @include transition(all);
                font-size: calculateRem(12px);
                color: getColor(red);
                position: absolute;
                right: 0;
                bottom: 0;
                cursor: pointer;
                padding: calculateRem(6px) 0;
                display: block;

                &:hover {
                    transform: translateX(-5px);
                }

                &:active,
                &:focus {
                    outline: none;
                }
            }
        }
    }

    &.left {
        grid-template-areas: 'swiper titles' 'swiper content';

        .carousel__title {
            -ms-grid-row: 1;
            -ms-grid-column: 3;
            -ms-grid-row-align: end;

            grid-area: titles;
            align-self: end;
        }

        .carousel__content {
            -ms-grid-row: 2;
            -ms-grid-column: 3;

            grid-area: content;

            .btn {
                right: auto;
                left: 0;
                transform: translate(-50%, 50%) translate(-20px, 4px);
            }
        }

        .carousel__swiper {
            -ms-grid-row: 1;
            -ms-grid-column: 1;

            .swiper-next {
                right: auto;
                left: 0;
                transform: rotate(180deg) translateX(0);

                &:hover {
                    transform: rotate(180deg) translateX(-5px);
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        &.has-cta {
            padding-bottom: calculateRem(30px);
        }

        .carousel__title,
        .carousel__content {
            padding: 0;
        }

        .carousel__content {
            .btn {
                position: relative;
                transform: none;
            }
        }

        &.left {
            .carousel__content {
                .btn {
                    position: relative;
                    transform: none;
                }
            }
        }
    }

    @include media-breakpoint-portrait(tablet) {
        .carousel__title,
        .carousel__content {
            padding: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        grid-row-gap: calculateRem(15px);

        -ms-grid-rows: auto auto auto;
        grid-template-rows: auto auto auto;

        -ms-grid-columns: auto;
        grid-template-columns: auto;

        grid-template-areas: 'titles' 'swiper' 'content';

        &.has-cta {
            padding-bottom: calculateRem(30px);
        }

        .carousel__swiper {
            .swiper-container {
                padding: 0 0 calculateRem(24px) 0;

                .swiper-next {
                    @include transition(all);
                    font-size: calculateRem(12px);
                    color: getColor(red);
                    position: absolute;
                    //right: 0;
                    //top: 0;
                    cursor: pointer;
                    padding: calculateRem(6px) 0;
                    display: block;
                    bottom: auto;

                    &:hover {
                        transform: translateX(-5px);
                    }

                    &:active,
                    &:focus {
                        outline: none;
                    }
                }
            }
        }

        .carousel__title {
            padding: 0;
            h1 {
                margin-bottom: 0;
            }
        }

        .carousel__content {
            padding: 0;

            .btn {
                position: relative;
                transform: none;
                margin: 0 auto;
            }
        }

        .carousel__swiper {
            min-height: 0;
        }

        &.left {
            grid-template-areas: 'titles' 'swiper' 'content';

            .swiper-container {
                padding: 0 0 calculateRem(24px) 0;

                .swiper-next {
                    @include transition(all);
                    font-size: calculateRem(12px);
                    color: getColor(red);
                    position: absolute;
                    left: auto;
                    right: 0;
                    //top: 0;
                    cursor: pointer;
                    padding: calculateRem(6px) 0;
                    display: block;
                    bottom: auto;
                    transform: rotate(0deg) translateX(0);

                    &:hover {
                        transform: rotate(0deg) translateX(-5px);
                    }

                    &:active,
                    &:focus {
                        outline: none;
                    }
                }
            }

            .carousel__content {
                .btn {
                    position: relative;
                    transform: none;
                    margin: 0 auto;
                }
            }
        }
    }
}
