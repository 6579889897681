section {
    width: 100%;
    padding: calculateRem(45px) 0;
    position: relative;
    opacity: 0;
    transform: translateY(-40px);

    &:first-of-type,
    .magnolia-editor & {
        opacity: 1;
        transform: none;
        padding-top: 0;
    }

    .header-image + & {
        padding-top: calculateRem(45px);
    }

    & + section {
        padding-top: calculateRem(15px);
    }

    .container {
        margin: 0 auto;
    }

    &.hidden,
    &.in-carousel {
        display: none;
    }

    &.invisible {
        border: 1px solid red;
        opacity: 0;
        position: absolute;
        left: -9999px;
        visibility: hidden;
        top: 0;
        z-index: -1;
    }

    &.is-result {
        display: flex;
    }

    &.magnolia-editor.in-carousel {
        border: 4px solid getColor(red);

        &:after {
            content: 'visible in carousel';
            padding: calculateRem(10px) calculateRem(20px);
            background: getColor(red);
            font-size: calculateRem(21px);
            color: getColor(white);
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            text-transform: uppercase;
            letter-spacing: 0;
            border-radius: 0 0 4px 4px;
        }
    }

    @include media-breakpoint-down(lg) {
        padding: calculateRem(30px) 0;
    }

    @include media-breakpoint-down(sm) {
        padding: calculateRem(20px) 0;

        &.full {
            padding: calculateRem(20px) 0;
        }
    }
}
