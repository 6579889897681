.image-hover {
    overflow: hidden;
    position: relative;
    width: 50%;

    .teaser & {
        width: auto;
    }

    .image-wrapper {
        transition: transform 0.6s ease-out;
        background-size: cover;
        background-position: center center;
        height: 100%;
        width: auto;
        padding-bottom: 100%;
    }

    &:hover {
        .image-wrapper {
            transform: scale(1.015);
        }
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
        margin-bottom: calculateRem(15px);
    }
}

.teaser {
    .image-hover {
        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
        }
    }
}
