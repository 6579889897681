.content.editor {
    section.invisible {
        position: initial;
        opacity: initial;
        left: initial;
        visibility: visible;
        top: initial;
        z-index: initial;
    }
}
