.search-bar {
    position: relative;

    .toggle-searchform {
        @include transition(all);
        cursor: pointer;
        border: none;
        font-size: calculateRem(16px);
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        padding: 0;
        height: $input-height;
        width: $input-height;
        line-height: $input-height;
        background-color: transparent;
        color: getColor(gray);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &:focus {
            outline: none;
        }

        &:hover {
            color: getColor(blue);
        }
    }

    form {
        @include transition(all);
        background-color: getColor(white);
        opacity: 0;
        width: 0;
        position: absolute;
        right: $input-height;
        top: 0px;
        z-index: -1;

        input {
            border: 1px solid getColor(gray, light);
            border-right: 0;
            padding: 0 calculateRem(15px);
        }
    }

    &.expanded {
        z-index: 1;

        .toggle-searchform {
            background-color: getColor(red);
            color: getColor(white);
        }

        form {
            opacity: 1;
            width: 240px;
            z-index: 0;
        }
    }
}
