.bar-18 {
    font-size: calculateRem(15px);
    line-height: calculateRem(15px);
    background-color: getColor(blue);
    font-family: Arial, sans-serif;
    font-style: italic;
    display: block;
    color: getColor(white);
    padding: calculateRem(8px);
    height: $bar18-height;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 101;
    display: flex;
    flex-direction: row;
    align-items: center;

    .nix-18-logo {
        height: 15px;
    }

    .about-alcohol {
        position: absolute;
        right: 30px;
        top: 0;
        text-decoration: underline;

        @include media-breakpoint-down(sm) {
            position: relative;
            padding: 0;
            right: auto;
            top: auto;
        }
    }

    a {
        text-decoration: none;
        color: getColor(white);
    }

    @include media-breakpoint-down(md) {
        padding-left: 0;
    }

    @include media-breakpoint-down(sm) {
        @include transition(all);
        font-size: calculateRem(15px);
        padding: calculateRem(10px) 0;
        height: $bar18-height-mobile;

        a {
            font-size: inherit;
        }

        .nav-expanded & {
            transform: translateX(-85%);
        }
    }

    .gated & {
        // display: none;
    }
}
