$checkboxSize: 20px;
$checkboxCheckedSize: 12px;

input[type='checkbox'].form-checkbox {
    position: absolute;
    left: -9999px;
    display: initial;
    width: auto;

    & + label {
        color: inherit !important;
        cursor: pointer;
        position: relative;
        padding-left: calculateRem(30px);
        max-width: 100%;
        display: inline-block;
        text-align: left;
        line-height: 100%;

        &:before {
            @include transition(all);
            font-size: calculateRem(16px);
            line-height: calculateRem(14px);
            display: inline-block;
            height: $checkboxSize;
            width: $checkboxSize;
            text-align: center;
            border-radius: 0;
            background: transparent;
            color: transparent;
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            padding-bottom: 0;
            border: 1px solid getColor(gray, light);
            content: '';
        }

        &:after {
            @include transition(all);
            height: 4px;
            width: 4px;
            background-color: getColor(gray, light);
            content: '';
            display: inline-block;
            left: ($checkboxSize - $checkboxCheckedSize) / 2;
            top: ($checkboxSize - $checkboxCheckedSize) / 2;
            position: absolute;
            opacity: 0;
        }
    }

    &:checked {
        & + label {
            &:after {
                height: $checkboxCheckedSize;
                width: $checkboxCheckedSize;
                opacity: 1;
            }
        }
    }

    &:disabled {
        & + label {
            color: getColor(gray);

            &:before {
                background: transparent !important;
                border: 1px solid getColor(gray) !important;
                color: getColor(gray) !important;
            }
        }
    }
}
