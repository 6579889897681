.btn {
    @include transition(all);
    @include font-family(heading);
    line-height: $input-height;
    min-height: $input-height;
    background-color: getColor(red);
    color: getColor(white);
    text-transform: uppercase;
    padding: 0 15px;
    font-size: calculateRem(16px);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    position: relative;
    display: inline-block;
    z-index: 0;
    cursor: pointer;
    border: 0;

    &.bordered {
        position: relative;
        @include transition(all);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        text-align: center;
        line-height: 18px;
        padding: calculateRem(15px) calculateRem(15px) calculateRem(18px);
        width: 100px;
        height: 100px;
        color: getColor(red);
        background: none;
        margin-top: calculateRem(20px);

        svg {
            @include transition(transform);
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;

            path {
                @include transition(all);
            }
        }

        &:hover,
        &:focus,
        &:active {
            outline: none;
            color: getColor(blue);
            background: none;
            text-decoration: none;

            svg {
                transform: rotate(-5deg);

                path {
                    fill: getColor(blue);
                }
            }
        }
    }

    &:hover,
    &:focus,
    &:active {
        outline: none;
        text-decoration: none;
    }

    &:hover {
        background-color: lighten(getColor(red), 5%);
    }

    &.facebook-login {
        background-color: getColor(blue);
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: calculateRem(30px);
        padding-left: $input-height;

        i {
            font-size: calculateRem(30px);
            position: absolute;
            left: 0;
            top: 0;
            width: $input-height;
            height: $input-height;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: calculateRem(8px);
            background-color: darken(getColor(blue), 6%);
        }

        &:hover {
            background-color: lighten(getColor(blue), 5%);
        }
    }

    &.btn--icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: 1.4em;
            margin-left: 10px;
        }
    }
}
