.campaign-component,
.tailor-made-campaign-component {
    background: getColor(white);
    margin: 15px 0;
    @include media-breakpoint-down(md) {
        padding: 0;
    }
    .container {
        @include media-breakpoint-down(md) {
            padding: 15px;
        }
    }

    .progress {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0 0 15px;
        list-style-type: none;

        li {
            &:after {
                content: '';
                height: 10px;
                width: 10px;
                border: 1px solid getColor(gray, border);
                background-color: transparent;
                border-radius: 50%;
                display: inline-block;
                margin: 0 5px;
            }

            &.active {
                &:after {
                    background-color: getColor(gray, border);
                }
            }
        }
    }

    .image {
        display: flex;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 300px;
    }
    .campaign-content {
        padding-left: 30px;
        @include media-breakpoint-portrait(md) {
            padding: 0;
            margin: 0 !important;
        }

        .campaign-title {
            @include font-weight(bold);

            span {
                color: getColor(red);
            }
        }

        .campaign-subtitle {
            span {
                color: getColor(red);
            }
        }

        .campaign-screen-one {
            display: none;

            &.showing {
                display: block;
            }

            .campaign-code-input {
                max-width: 360px;
                @include media-breakpoint-down(xs) {
                    max-width: 100%;
                }

                form {
                    margin: 25px 0;

                    .append-button {
                        @include media-breakpoint-down(xs) {
                            display: block;
                            button {
                                width: 100%;
                                margin-top: 5px;
                                margin-left: 0;
                            }
                        }
                    }
                }

                .check-code-button {
                    &.red {
                        background-color: getColor(red);

                        &:hover {
                            background-color: darken(getColor(red), 5%);
                        }
                    }

                    &.orange {
                        background-color: orange;

                        &:hover {
                            background-color: darken(orange, 5%);
                        }
                    }

                    &.black {
                        background-color: getColor(black);

                        &:hover {
                            background-color: darken(getColor(gray), 5%);
                        }
                    }

                    &.green {
                        background-color: getColor(green);

                        &:hover {
                            background-color: darken(getColor(green), 5%);
                        }
                    }
                }

                .campaign-info {
                    @include font-size(14px);
                    @include line-height(20px);
                }
            }
        }

        .campaign-screen-two,
        .campaign-screen-main {
            display: none;

            .are-you-winner {
                margin-top: 10px;
            }

            &.showing {
                display: block;
            }

            .drop-region {
                background-color: #fff;
                border-radius: 3px;
                width: 100%;
                padding: 60px 40px;
                text-align: center;
                cursor: pointer;
                transition: 0.3s;
                border: 1px solid getColor(gray, border);

                .image-upload-icon {
                    font-size: 40px;
                    color: getColor(gray);
                }

                #image-preview-wrapper {
                    #img-preview {
                        width: 100%;
                    }
                }
            }

            .required-fields {
                display: block;
                margin-bottom: 20px;
                font-weight: bold;
            }

            .form-group.inline.checkboxes {
                @include media-breakpoint-down(xs) {
                    display: block;

                    .send {
                        margin-top: 15px;
                    }
                }
            }

            .row {
                margin-bottom: 15px;

                @include media-breakpoint-down(xs) {
                    margin-bottom: 0;
                }

                .form-group {
                    @include media-breakpoint-down(md) {
                        display: block;
                    }

                    .form-control-label {
                        width: 100%;

                        @include media-breakpoint-down(xs) {
                            margin-bottom: 15px;
                        }

                        label.error {
                            display: none;
                            position: static;

                            &.showing {
                                display: block;
                            }
                        }

                        input {
                            width: 100%;

                            &:nth-child(1) {
                                @include media-breakpoint-down(md) {
                                    margin-right: 0;
                                    margin-bottom: 10px;
                                }
                            }

                            &:nth-child(2) {
                                margin-right: 0;
                            }
                        }

                        small {
                            line-height: 125%;
                            display: block;
                            margin-top: 5px;
                        }
                    }

                    .form-check {
                        label.error-checkbox {
                            animation: shake 1s ease-out;

                            &:before {
                                border-color: getColor(red);
                            }

                            span {
                                color: getColor(red);
                            }
                        }
                    }
                }

                &.critical-error {
                    display: none;

                    .col {
                        padding: 0;

                        .error {
                            background: getColor(red);
                            padding: 5px;
                            color: getColor(white);
                        }
                    }
                }

                &.campaign-screen-two-closure {
                    .col {
                        @include media-breakpoint-down(md) {
                            padding: 0 !important;
                        }

                        &:nth-child(1) {
                            margin-right: 10px;
                        }

                        &:nth-child(2) {
                            display: flex;
                            align-items: center;
                            @include media-breakpoint-down(md) {
                                margin-bottom: 0 !important;
                            }

                            button {
                                width: 100%;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .campaign-screen-win {
            display: none;

            &.showing {
                display: block;
            }

            .win-image-gif {
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 300px;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
        }

        .campaign-screen-lose {
            display: none;

            .lose-image-gif {
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 300px;
            }

            &.showing {
                display: block;
            }
        }

        @include media-breakpoint-down(sm) {
            .hdh-order-xs {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;

                .campaign-gifty-wrapper {
                    order: 1;
                }

                .campaign-button-wrapper {
                    order: 2;
                }

                .campaign-text-wrapper {
                    order: 3;
                }
            }
        }

        .campaign-gifty-wrapper {
            .gifty-code {
                .copy-gifty-code {
                    position: absolute;
                    left: -99999px;
                }

                span {
                    @include font-family(heading-bold);
                    font-size: 24px;
                    line-height: 24px;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    margin-bottom: 20px;
                    //color: getColor(theme2, 'dark');
                    display: block;
                    cursor: pointer;

                    &.grow-font {
                        animation: grow-font 0.2s alternate;
                    }
                }
            }
        }

        @keyframes grow-font {
            0% {
                font-size: 24px;
            }
            100% {
                font-size: 28px;
            }
        }
    }

    .error {
        border: 1px solid getColor(red) !important;

        &-parent {
            @extend .error;
            border-radius: 3px;
        }
    }

    label {
        &.error {
            display: none;
            background-color: #d2232a;
            color: #fff;
            padding: 4px 8px;
            margin: -2px 0 0 0 !important;
            border-radius: 0 0 3px 3px;
            width: 100%;
            position: absolute;
            bottom: -23px;
            @include media-breakpoint-down(sm) {
                position: static;
            }

            &.showing {
                display: block;
            }
        }
    }

    &.no-legacy {
        .mb-1 {
            margin-bottom: 15px;
        }
        .mb-2 {
            margin-bottom: 30px;
        }

        .campaign-content {
            text-align: center;

            .campaign-title,
            .campaign-subtitle {
                @include font-weight(regular);

                strong {
                    @include font-weight(bold);
                }
            }

            @include media-breakpoint-down(sm) {
                .campaign-subtitle {
                    font-size: calculateRem(16px);
                }
            }

            img.full-width {
                width: 100%;
                height: auto;
            }

            form {
                text-align: left;

                @include media-breakpoint-down(xs) {
                    input,
                    select,
                    textarea {
                        font-size: 16px;
                    }
                }

                .form-group.inline {
                    .form-control-label {
                        margin-right: 30px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }

                .form-checkbox {
                    &.error + label {
                        animation: shake 1s ease-out;
                        color: getColor(red) !important;

                        &:before {
                            border-color: getColor(red);
                        }
                    }
                }

                .error-message {
                    display: none;
                    background-color: getColor(red);
                    color: getColor(white);
                    padding: 6px 10px;
                    border-radius: 0 0 3px 3px;
                    width: 100%;
                    font-size: 12px;
                    line-height: 100%;
                    margin-top: -2px;
                    font-weight: bold;

                    &.showing {
                        display: block;
                    }
                }

                .btn-center {
                    margin: 0 auto;
                }
            }

            .text-image {
                .image {
                    min-height: 0;

                    img {
                        max-width: 100%;
                        min-height: 0;
                        height: auto;
                    }
                }

                @include media-breakpoint-down(xs) {
                    .container .col {
                        padding: 0;
                    }

                    .image img {
                        width: 100%;
                    }
                }
            }

            .campaign-screen-one .campaign-code-input {
                margin: 45px auto;
                max-width: none;

                .form-group.append-button {
                    margin: 0 auto;

                    input {
                        width: 200px;
                        height: 42px;
                        text-transform: uppercase;
                        line-height: 42px/100%;
                    }

                    button {
                        height: 42px;
                        padding-top: 0;
                        padding-bottom: 0;
                        line-height: 100%;
                        margin-right: 0;
                    }

                    @include media-breakpoint-down(xs) {
                        width: 100%;

                        input {
                            width: 100%;
                            height: 42px;
                            line-height: 100%;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    &.magnolia-editor {
        .campaign-screen-one,
        .campaign-screen-two,
        .campaign-screen-win,
        .campaign-screen-lose,
        .campaign-screen-main {
            display: block;
            padding: 30px 0;
            border-bottom: 1px dashed getColor(gray);
        }
    }
}
