$font-families: (
    heading: 'DINCond-Black',
    medium: 'DINCond-Medium',
    subheading: 'BodoniSvtyTwoITCTT-BookIta',
    body: 'TradeGothicLT-Light',
);

@each $d, $f in $font-families {
    @font-face {
        font-family: $f;
        src: url('fonts/#{$f}.eot');
        src: url('fonts/#{$f}.eot?#iefix') format('embedded-opentype'), url('fonts/#{$f}.woff') format('woff'),
            url('fonts/#{$f}.ttf') format('truetype'), url('fonts/#{$f}.svg') format('svg');
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
    }
}

$font-weights: (
    regular: 400,
    bold: 600,
);

$headings: (
    h1: (
        font-family: heading,
        font-size: 48px,
        line-height: 48px,
        margin-bottom: 15px,
        word-wrap: break-word,
        text-transform: uppercase,
        font-weight: regular,
    ),
    h2: (
        font-family: heading,
        font-size: 32px,
        line-height: 32px,
        margin-bottom: 15px,
        text-transform: uppercase,
        word-wrap: break-word,
        font-weight: regular,
    ),
    h3: (
        font-family: subheading,
        font-size: 20px,
        line-height: 22px,
        margin-bottom: 10px,
        word-wrap: break-word,
        font-weight: regular,
    ),
    h4: (
        font-family: body,
        font-size: 16px,
        line-height: 24px,
        margin-bottom: 5px,
        font-weight: bold,
    ),
    h5: (
        font-family: heading,
        font-size: 16px,
        line-height: 20px,
        margin-bottom: 5px,
        font-weight: regular,
    ),
);
@each $label, $props in $headings {
    #{$label},
    .#{$label} {
        @include font-family(map-get($props, font-family));
        @include font-weight(map-get($props, font-weight));
        font-size: calculateRem(map-get($props, font-size));
        line-height: calculateRem(map-get($props, line-height));
        @if map-has-key($props, letter-spacing) {
            letter-spacing: calculateRem(map-get($props, letter-spacing));
        }
        @if map-has-key($props, font-style) {
            font-style: map-get($props, font-style);
        }
        @if map-has-key($props, text-transform) {
            text-transform: map-get($props, text-transform);
        }
        @if map-has-key($props, word-wrap) {
            word-wrap: map-get($props, word-wrap);
        }
        margin-bottom: calculateRem(map-get($props, margin-bottom));
    }
}
html {
    font-size: 16px;
}
body {
    @include font-family(body);
    line-height: calculateRem(24px);
    color: getColor(gray);
}
h1,
h2,
h3,
h4,
h5,
p {
    margin-top: 0;
}
p {
    line-height: calculateRem(24px);
}
.uc {
    text-transform: uppercase;
}

h1,
h2,
h4 {
    color: getColor(blue);
}

h2 a:hover {
    text-decoration: none;
}

strong {
    color: getColor(blue);
}
