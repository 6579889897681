.customOverlaybc .lightboxWrapper.customOverlaybc {
    border-radius: 5px;
    width: 95%;
    font-family: TradeGothicLT-Light;
}

.customOverlaybc .primaryButtonConsent,
.customOverlaybc .secondaryButtonConsent {
    border-radius: 5px;
    -webkit-appearance: none;
    font-family: DINCond-Black;
    font-size: 16px;
    font-size: 1.0625rem;
    -webkit-transition: background 0.2s ease;
    -o-transition: background 0.2s ease;
    transition: background 0.2s ease;
    line-height: 17px;
    line-height: 1.0625rem;
    font-weight: 400;
    border: 1px solid;
    color: #fff;
    padding: 12px 18px;
    width: auto;
    display: inline-block;
    margin-bottom: 15px;
    height: auto;
    background-color: #c8073c;
    text-transform: uppercase;
}

.customOverlaybc .secondaryButtonConsent {
    background-color: #fff;
    color: #c8073c;
    border-color: #c8073c;
}

.customOverlaybc .primaryButtonConsent {
    background-color: #c8073c;
}

.customOverlaybc .secondaryButtonConsent:hover {
    color: #c8073c;
    border-color: #c8073c;
}

.customOverlaybc .primaryButtonConsent:hover {
    background-color: #c8073c;
}

.customOverlaybc .objectiveInput {
    right: 10px;
}

.customOverlaybc .objectiveInput:not(:checked) + .objectiveInputLabel {
    color: #023e84;
    background-color: #fff;
    border-color: #c8073c;
}

.customOverlaybc .objectiveInput:checked + .objectiveInputLabel {
    background-color: #c8073c;
    border-color: #c8073c;
    color: #fff;
}

.customOverlaybc .objectiveText {
    margin-right: 0px;
    font-family: TradeGothicLT-Light;
}

.customOverlaybc .objectiveTitle {
    font-weight: bold;
    font-size: 1.2em;
    font-family: DINCond-Black;
}

.customOverlaybc .basicObjective {
    opacity: 0.5;
}

.customOverlaybc .buttonWrapperConsent {
    max-width: none;
    margin-top: 20px;
    white-space: normal;
}

.customOverlaybc .objectiveWrapper {
    border-top: 2px solid #023e84;
    padding-top: 15px;
}

.customOverlaybc .objectiveWrapper:last-of-type {
    border-bottom: 2px solid #023e84;
    padding-bottom: 15px;
}

.customOverlaybc .objectiveInputWrapper {
    align-items: flex-start;
    justify-content: right;
    margin-bottom: 10px;
    height: fit-content;
    font-family: DINCond-Black;
}

@media (min-width: 720px) {
    .customOverlaybc .lightboxWrapper.customOverlaybc {
        width: 700px;
    }

    .customOverlaybc .objectiveText {
        margin-right: 20px;
    }

    .customOverlaybc .primaryButtonConsent,
    .secondaryButtonConsent {
        font-size: 17px;
    }

    .customOverlaybc .buttonWrapperConsent {
        white-space: nowrap;
    }
}
